import Vue from 'vue'
import Vuex from 'vuex'
import { getToken, setToken, removeToken, setLoc } from '@/utils/auth'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: getToken(),
    sid:'',
  },
  getters: {
    token: state => state.token,
    sid: state => state.sid,
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_SID: (state, sid) => {
      state.sid = sid
  },
  },
  actions: {
  },
  modules: {
  }
})
