import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import( '../views/index.vue')
  },
  {
    path: '/bk',
    name: 'bk',
    component: () => import( '../views/bk.vue')
  }

 
]

const router = new VueRouter({
  routes
})

export default router
